import React, { Component } from "react";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

import EventBus from "../../common/EventBus";
import ReportsService from "../../services/api/reports.service";
import { getRapportiniCommesseFormFields } from "../../config/formFields";
import { openBase64NewTab } from '../../common/Base64';
import Swal from "sweetalert2";
import { FormFields, FormFieldsValue } from "../../helpers/interfaces/generic";
import moment from "moment";
import "moment/locale/it";
import { numberRange } from "../../common/NumberRanger";

type Props = {
    renderField: (item: FormFields, key: number, formik: FormikProps<any>) => false | JSX.Element,
    persons: FormFieldsValue[],
    joborders: FormFieldsValue[],
    joborders_all: any[],
};
type State = {
    loading: boolean,
    formFields: Array<FormFields>,
    years: FormFieldsValue[],
    months: FormFieldsValue[],
    year: string,
    month: string,
    joborders_filtered: any[],
    disabledForm: boolean,
    formInitialValues: { [key: string]: any }
}

export default class RapportiniCommesse extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            years: [],
            months: [],
            year: '',
            month: '',
            joborders_filtered: [],
            disabledForm: true,
            formInitialValues: {}
        }
    }

    componentDidMount(): void {
        const years_all = numberRange(2016, moment().year()).reverse();
        let years: FormFieldsValue[] = years_all.map((item: any) => { return { key: item, value: item } });

        const months_all = numberRange(1, 12);
        let months: FormFieldsValue[] = months_all.map((item: any) => { return { key: item, value: item } });

        const joborders = this.props.joborders.sort((commessaA, commessaB) => commessaA.value.localeCompare(commessaB.value));
        this.setState({
            years,
            months,
            formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this))
        });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.joborders !== this.props.joborders) {
            const {years, months} = this.state;

            const joborders = this.props.joborders.sort((commessaA, commessaB) => commessaA.value.localeCompare(commessaB.value));
            this.setState({
                formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this))
            });
        }
    }

    updateYearField(year: string): void {
        this.setState({ year: year });
        const {years, months} = this.state;
        let joborders_all;

        if (year) {
            const selectedYear = moment(year).year();

            joborders_all = this.props.joborders_all.filter(joborder => {
                if (!joborder.start) {joborder.start = '2016-01-01'};

                return (joborder.start && moment(joborder.start).year() === selectedYear) || 
                    (joborder.expired && moment(joborder.expired).year() === selectedYear) ||
                    ((joborder.start && moment(joborder.start).year() <= selectedYear) && 
                        (!joborder.expired || (joborder.expired && moment(joborder.expired).year() >= selectedYear)))
            });
        } else {
            joborders_all = this.props.joborders_all;
        }

        if (this.state.month) {
            this.setState({ joborders_filtered: joborders_all });
            setTimeout(() => {this.updateMonthField(this.state.month)}, 100);
        } else {
            const _joborders = joborders_all.map((item) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' }});
            const joborders = _joborders.sort((commessaA, commessaB) => commessaA.value.localeCompare(commessaB.value));
            this.setState({
                joborders_filtered: joborders_all,
                formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this))
            });
        }
    }

    updateMonthField( month: string ): void {
        this.setState({ month: month });
        const {years, months} = this.state;

        if (!this.state.year) {
            this.setState({formFields: getRapportiniCommesseFormFields(years, months, [], this.updateYearField.bind(this), this.updateMonthField.bind(this)) });
            Swal.fire({
                title: 'Attenzione',
                text: 'Si deve prima selezionare un anno.',
                icon: 'warning',
            });
        } else {
            const _month = month.length === 2 ? month : "0" + month;
            const date = this.state.year + "-" + _month + "-01";

            let joborders_all;

            if (month) {
                const selectedYear = moment(this.state.year).year();
                const selectedMonth = moment(date).month();

                joborders_all = this.state.joborders_filtered.filter(joborder => {
                    if (!joborder.start) {joborder.start = '2016-01-01'};

                    return (joborder.expired && moment(joborder.expired).year() > selectedYear) || 
                        ((joborder.expired && moment(joborder.expired).year() === selectedYear) && moment(joborder.expired).month() >= selectedMonth)
                });
            } else {
                joborders_all = this.state.joborders_filtered;
            }

            const _joborders = joborders_all.map((item) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } });
            const joborders = _joborders.sort((commessaA, commessaB) => commessaA.value.localeCompare(commessaB.value));
            this.setState({
                formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this))
            });
        }
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    async handleUpdate(formValue: any) {
        EventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });
        const report: any = await ReportsService.joborder(formValue.year, formValue.month, formValue.joborder);
        if (typeof report.body !== 'undefined') {
            openBase64NewTab(report);
        } else {
            this.setState({
                loading: false,
            }, () => {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la generazione del report.',
                    icon: 'error',
                });
            });
        }
        EventBus.dispatch("hideLoader");
    }

    render() {
        const { loading, formFields } = this.state;
        let initialValues: any = {};
        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate.bind(this)}
            >
                {(formik: FormikProps<any>) => {
                    return <Form className="px-3 pt-3">
                        {formFields && formFields.map((item: FormFields, key: number) => {
                            return <div className="form-group mb-3 row" key={key}>

                                {this.props.renderField(item, key, formik)}

                                <ErrorMessage
                                    name={item.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        })}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                )}
                                <span>Genera</span>
                            </button>
                        </div>
                    </Form>
                }}

            </Formik>
        </React.Fragment>
    }
}