import * as Yup from "yup";
import { FormFields, FormFieldsValue } from "../../helpers/interfaces/generic";

export function getRapportiniMensileFormFields(dipendenti: FormFieldsValue[]): Array<FormFields> {
  return [
    {
      'label': 'Mese',
      'name': 'date',
      'type': 'month',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Dipendenti',
      'name': 'person_id',
      'type': 'select',
      'values': dipendenti,
      'class': 'form-select',
    },
    {
      'label': 'Genera report per il consulente del lavoro',
      'name': 'perconsulente',
      'type': 'checkbox',
      'class': 'form-check-input'
    },
  ]
}

export function getRapportiniAnnualeFormFields(anni: any, dipendenti: any): Array<FormFields> {
  return [
    {
      'label': 'Anno',
      'name': 'year',
      'type': 'select',
      'value': '- Seleziona un anno -',
      'values': anni,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Dipendenti',
      'name': 'personId',
      'type': 'select',
      'value': '- Seleziona un dipendente -',
      'values': dipendenti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
  ]
}

export function getRapportiniCommesseFormFields(
  anni: FormFieldsValue[],
  mesi: FormFieldsValue[],
  commesse: FormFieldsValue[],
  updateYearField: (value: string) => void,
  updateMonthField: (value: string) => void
): Array<FormFields> {
  
  const elencoAnni: any = [];
  for (let i=0; i <= anni.length-1; i++) {
    elencoAnni.push(anni[i].value);
  }

  return [
    {
      'label': 'Anno',
      'name': 'year',
      'type': 'select',
      'value': '- Seleziona un anno -',
      'values': anni,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio"),
      'updateField': 'year',
      'updateCallback': updateYearField
    },
    {
      'label': 'Mese',
      'name': 'month',
      'type': 'select',
      'values': mesi,
      'class': 'form-select',
      'updateField': 'month',
      'updateCallback': updateMonthField,
      // 'showFromFieldName': 'year',
      // 'showFromFieldValue': elencoAnni
    },
    {
      'label': 'Commessa',
      'name': 'joborder',
      'type': 'select',
      'values': commesse,
      'class': 'form-select'
    },
  ]
}

export function getRapportiniMaterialeFormFields(stati: any): Array<FormFields> {
  return [
    {
      'label': 'Stato dipendente',
      'name': 'stato',
      'type': 'select',
      'values': stati,
      'class': 'form-select',
      'validation': Yup.string()
    }
  ]
}

export function getRapportiniAnagraficaFormFields(stati: any): Array<FormFields> {
  return [
    {
      'label': 'Stato dipendente',
      'name': 'stato',
      'type': 'select',
      'values': stati,
      'class': 'form-select',
      'validation': Yup.string()
    }
  ]
}